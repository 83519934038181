import React from "react"

import Layoutcs from "../../../components/layoutcs/layoutcs"
import SEO from "../../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Footer from "../../../components/footer/footer"

import "../../index.scss"
import "../../our-work.scss"
import "./sprava-php-aplikaci.scss"

import VideoBg from "../../../videos/ftrs-video.mp4"

import { Link } from "gatsby"

import ImgBlog11 from "../../../images/blog/modernizace-php-aplikaci-1.jpg"
import ImgBlog12 from "../../../images/blog/modernizace-php-aplikaci-2.jpg"
import ImgBlog13 from "../../../images/blog/modernizace-php-aplikaci-3.jpg"

import ImgTechStack1 from "../../../images/techstack/php.svg"
import ImgTechStack2 from "../../../images/techstack/nette.svg"
import ImgTechStack3 from "../../../images/techstack/mysql.svg"
import ImgTechStack4 from "../../../images/techstack/elasticsearch.svg"
import ImgTechStack7 from "../../../images/techstack/bootstrap.svg"
import ImgTechStack12 from "../../../images/techstack/sentry.svg"
import ImgTechStack13 from "../../../images/techstack/docker.svg"
import ImgTechStack15 from "../../../images/techstack/aws.svg"

const SpravaPhp = () => (

    <Layoutcs>
        <SEO title="Modernizace a správa PHP aplikací" description="Daří se nám ze zastaralého kódu vytvářet moderní, udržitelné a bezpečné aplikace, které dobře vypadají a mohou se bez problémů rozšířit o nové funkcionality." />

        <div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content ftrs-our-work">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Modernizace a správa PHP&nbsp;aplikací</h1>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text mt2 mb3">Svěřte nám svou starší aplikaci - pomůžeme Vám s&nbsp;analýzou a&nbsp;realizací jejího upgradu. Máme mnohaleté zkušenosti s&nbsp;údržbou a&nbsp;rozvojem PHP aplikací. Daří se nám ze&nbsp;zastaralého kódu vytvářet moderní, udržitelné a&nbsp;bezpečné aplikace, které dobře vypadají a&nbsp;mohou se bez&nbsp;problémů rozšířit o&nbsp;nové funkcionality.</p>

        </Container>

        <div className="ftrs-app">

			<Container>

				<Row>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt4">
						<h2 className="ftrs-h2">Analýza stávající PHP&nbsp;aplikace</h2>
						Naše první kroky zahrnují hloubkovou analýzu vaší aplikace. Identifikujeme slabé stránky, zastaralé kódové struktury a&nbsp;potenciální bezpečnostní rizika. Tento proces nám umožňuje vytvořit komplexní plán zlepšení aplikace, a&nbsp;to nejen z&nbsp;hlediska výkonu a&nbsp;bezpečnosti, ale také z&nbsp;pohledu udržitelnosti a&nbsp;rozšiřitelnosti. Tímto přístupem zajišťujeme, že&nbsp;vaše aplikace zůstane konkurenceschopná a&nbsp;přizpůsobivá budoucím požadavkům.
					</Col>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt4">
						<h2 className="ftrs-h2">Aktualizace technologie</h2>
						Aby vaše aplikace zůstala bezpečná a&nbsp;relevantní, je&nbsp;důležitý pravidelný technologický update. Zaměřujeme se na&nbsp;implementaci nejnovějších verzí PHP a&nbsp;moderních frameworků, jako je Nette, které přináší zlepšení v&nbsp;oblasti bezpečnosti, výkonu a&nbsp;uživatelské přívětivosti. Tato aktualizace zahrnuje také přechod na&nbsp;lepší databázové systémy a&nbsp;implementaci nejnovějších knihoven a&nbsp;API.
					</Col>
				</Row>

				<Row>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt2">
						<h2 className="ftrs-h2">Bezpečnostní aspekty</h2>
						V oblasti bezpečnosti přistupujeme k&nbsp;vašim PHP&nbsp;aplikacím s&nbsp;maximální péčí. Zaměřujeme se na&nbsp;prevenci proti běžným hrozbám, jako je SQL injection a&nbsp;cross-site scripting, prostřednictvím implementace robustních bezpečnostních protokolů, šifrování a&nbsp;bezpečných ověřovacích procesů. Zajistíme, že&nbsp;vaše aplikace a&nbsp;data jsou chráněny před neoprávněným přístupem a&nbsp;útoky.
					</Col>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt2">
						<h2 className="ftrs-h2">Výkon a&nbsp;stabilita</h2>
						Optimalizace výkonu a&nbsp;stability vaší aplikace je pro&nbsp;nás prioritou. Implementujeme nejlepší praktiky pro&nbsp;zvýšení rychlosti a&nbsp;odezvy aplikací, včetně optimalizace databáze a&nbsp;kódu. Tím zlepšujeme uživatelskou zkušenost a&nbsp;zajišťujeme, že&nbsp;aplikace je spolehlivá a&nbsp;efektivně reaguje i&nbsp;při&nbsp;vysokém zatížení.
					</Col>
				</Row>

				<Row>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt2">
						<h2 className="ftrs-h2">Škálování</h2>
						Škálování vaší aplikace je klíčové pro&nbsp;podporu růstu vašeho podnikání. Nabízíme řešení pro&nbsp;horizontální a&nbsp;vertikální škálování, které umožňují vaší aplikaci snadno zvládat nárůst uživatelů a&nbsp;dat. Toto zahrnuje optimalizaci infrastruktury, zefektivnění zpracování dat a&nbsp;zajištění, že&nbsp;aplikace může růst bez&nbsp;kompromisů v&nbsp;oblasti výkonu a&nbsp;stability.
					</Col>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt2">
						<h2 className="ftrs-h2">Přidávání nových funkcí</h2>
						Rozšiřujeme možnosti vaší aplikace přidáním nových funkcí, které odpovídají aktuálním trendům a&nbsp;požadavkům trhu. Tento proces zahrnuje nejen vývoj a&nbsp;integraci nových modulů, ale také zajištění, že&nbsp;tyto nové funkce jsou plně kompatibilní se&nbsp;stávajícími částmi aplikace a&nbsp;neohrozí její celkovou stabilitu a&nbsp;výkon.
					</Col>
				</Row>

				<Row>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt2">
						<h2 className="ftrs-h2">Modernizace designu</h2>
						Design je klíčový pro&nbsp;první dojem a&nbsp;uživatelskou zkušenost. Naši designéři pracují na&nbsp;modernizaci uživatelského rozhraní vaší aplikace, aby&nbsp;bylo vizuálně přitažlivé a&nbsp;intuitivní. Zahrnujeme nejnovější trendy ve&nbsp;web designu a&nbsp;zajišťujeme, že&nbsp;design je responzivní a&nbsp;přizpůsobivý různým zařízením a&nbsp;obrazovkám.
					</Col>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt2">
						<h2 className="ftrs-h2">Automatizace a&nbsp;workflow</h2>
						Automatizace procesů je důležitá pro&nbsp;zvýšení efektivity a&nbsp;snížení možnosti chyb. Implementujeme automatizované workflow, včetně continuous integration a&nbsp;continuous delivery (CI/CD), které zvyšují efektivitu vývoje a&nbsp;udržují kvalitu aplikace. Tímto způsobem zjednodušujeme vývojový cyklus a&nbsp;zajišťujeme rychlejší nasazení nových funkcí a&nbsp;aktualizací.
					</Col>
				</Row>

				<Row>
					<Col xs="12" lg="6" data-sal="fade" data-sal-duration="1000" className="mt2">
						<h2 className="ftrs-h2">Integrace s&nbsp;dalšími systémy</h2>
						Integrace vaší PHP aplikace s&nbsp;dalšími systémy, jako jsou CRM nebo&nbsp;ERP, je klíčová pro&nbsp;zvýšení efektivity a&nbsp;automatizaci procesů. Naše řešení zajišťují, že&nbsp;vaše aplikace může komunikovat a&nbsp;synchronizovat data s&nbsp;dalšími systémy, což vede ke&nbsp;zvýšení produktivity a&nbsp;lepší správě dat.
					</Col>
				</Row>


				<Row className="mt5">

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<Link className="ftrs-blog__item ftrs-blog__item--white-bg" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-1-historie-php">
							<div className="ftrs-blog__img-cover ftrs-blog__img-cover--white-bg">
								<img className="ftrs-blog__img" src={ImgBlog11} alt="Modernizace PHP aplikací 1 - historie PHP" />
							</div>
							<div className="ftrs-blog__date">21.07.2023</div>
							<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;1 - historie&nbsp;PHP</h2>
						</Link>
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<Link className="ftrs-blog__item ftrs-blog__item--white-bg" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-2-duvody-k-aktualizaci">
							<div className="ftrs-blog__img-cover ftrs-blog__img-cover--white-bg">
								<img className="ftrs-blog__img" src={ImgBlog12} alt="Modernizace PHP aplikací 2 - důvody k aktualizaci" />
							</div>
							<div className="ftrs-blog__date">30.08.2023</div>
							<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;2 - důvody k&nbsp;aktualizaci</h2>
						</Link>
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<Link className="ftrs-blog__item ftrs-blog__item--white-bg" activeClassName="ftrs-blog__item--active" to="/cs/blog/modernizace-php-aplikaci-3-posouzeni-php-projektu">
							<div className="ftrs-blog__img-cover ftrs-blog__img-cover--white-bg">
								<img className="ftrs-blog__img" src={ImgBlog13} alt="Modernizace PHP aplikací 3 - posouzení PHP projektu" />
							</div>
							<div className="ftrs-blog__date">16.09.2023</div>
							<h2 className="ftrs-blog__title ftrs-h3">Modernizace PHP aplikací&nbsp;3 - posouzení php&nbsp;projektu</h2>
						</Link>
					</Col>

				</Row>

				<h2 className="ftrs-h2 ftrs-h2--big mt4" data-sal="fade" data-sal-duration="1000">Proč spolupracovat s&nbsp;námi?</h2>

				<Row className="mt3">

					<Col xs="12" md="6" lg="4" data-sal="fade" data-sal-duration="1000">
						<div className="php-reason__item">
							<h3 className="ftrs-h3">Expertíza a specializace</h3>
							<p className="php-reason__p">Členové našich vývojových týmů si&nbsp;neustále prohlubují znalosti a&nbsp;specializaci v&nbsp;konkrétních technologických oblastech. Tuto úroveň odborných znalostí může být obtížné vybudovat a&nbsp;udržovat interně, zvláště v&nbsp;případě komplexních nebo specifických technologií.</p>
						</div>
					</Col>

					<Col xs="12" md="6" lg="4" data-sal="fade" data-sal-duration="1000">
						<div className="php-reason__item">
							<h3 className="ftrs-h3">Nákladová efektivita</h3>
							<p className="php-reason__p">Outsourcing může být ekonomicky výhodnější než vytváření a&nbsp;udržování interních týmů. Ušetříte náklady na&nbsp;nábor, školení, platy a&nbsp;další výhody zaměstnanců, stejně jako na&nbsp;infrastruktuře a&nbsp;technologických zdrojích.</p>
						</div>
					</Col>

					<Col xs="12" md="6" lg="4" data-sal="fade" data-sal-duration="1000">
						<div className="php-reason__item">
							<h3 className="ftrs-h3">Flexibilita a&nbsp;škálovatelnost</h3>
							<p className="php-reason__p">Poskytujeme flexibilitu ve&nbsp;zvyšování nebo snižování rozsahu pracovní síly podle aktuálních potřeb projektu bez&nbsp;dlouhodobého závazku nebo&nbsp;složitostí s&nbsp;náborovými procesy.</p>
						</div>
					</Col>

					<Col xs="12" md="6" lg="4" data-sal="fade" data-sal-duration="1000">
						<div className="php-reason__item">
							<h3 className="ftrs-h3">Zaměřte se na&nbsp;své hlavní podnikání</h3>
							<p className="php-reason__p">Pomáháme vám soustředit se na&nbsp;vaše hlavní obchodní aktivity a&nbsp;strategické cíle, zatímco my řešíme technické aspekty a&nbsp;vývoj, čímž se snižuje pracovní zátěž a&nbsp;management je méně rozptýlený.</p>
						</div>
					</Col>

					<Col xs="12" md="6" lg="4" data-sal="fade" data-sal-duration="1000">
						<div className="php-reason__item">
							<h3 className="ftrs-h3">Snížení rizika</h3>
							<p className="php-reason__p">Přenesením projektů na&nbsp;náš zkušený tým se snižuje riziko spojené s&nbsp;vývojem a&nbsp;správou technologií. Máme již osvědčené procesy a&nbsp;postupy zvládání běžných výzev a&nbsp;problémů.</p>
						</div>
					</Col>

					<Col xs="12" md="6" lg="4" data-sal="fade" data-sal-duration="1000">
						<div className="php-reason__item">
							<h3 className="ftrs-h3">Přístup k&nbsp;nejnovějším technologiím</h3>
							<p className="php-reason__p">Máme přístup k&nbsp;nejnovějším technologiím a&nbsp;trendům, což může být náročné pro&nbsp;interní týmy, které se musejí soustředit na&nbsp;běžnou provozní činnost. Můžete tak těžit z&nbsp;moderních technologických řešení bez&nbsp;nutnosti neustálého vzdělávání nebo investic do&nbsp;nových technologií.</p>
						</div>
					</Col>

					<Col xs="12" md="6" lg="4" data-sal="fade" data-sal-duration="1000">
						<div className="php-reason__item">
							<h3 className="ftrs-h3">Rychlá reakce na&nbsp;tržní změny</h3>
							<p className="php-reason__p">Jsme schopni rychle reagovat na&nbsp;tržní změny a&nbsp;upravit strategie vývoje, což je často náročné pro&nbsp;interní týmy, které jsou zatíženy běžnými operacemi.</p>
						</div>
					</Col>

					<Col xs="12" md="6" lg="4" data-sal="fade" data-sal-duration="1000">
						<div className="php-reason__item">
							<h3 className="ftrs-h3">Zkušenosti s&nbsp;podobnými projekty</h3>
							<p className="php-reason__p">Máme mnohaleté zkušenosti s&nbsp;řešením podobných projektů a&nbsp;problémů, které jsou cenné pro&nbsp;klienty, kteří se mohou setkat s&nbsp;podobnými výzvami poprvé.</p>
						</div>
					</Col>

				</Row>

				<h2 className="ftrs-h2 mt4 mb2" data-sal="fade" data-sal-duration="1000">Na co se dále zaměříme</h2>

				<Row className="mt3">

				<Col xs="12" lg="8" data-sal="fade" data-sal-duration="1000">
						<ul className="php-focus" data-sal="fade" data-sal-duration="1000">
							<li className="php-focus__item">V jakém frameworku je aplikace napsaná a&nbsp;jak je možné provést jeho upgrade?</li>
							<li className="php-focus__item">Je aplikace verzovaná v&nbsp;GIT repozitáři a&nbsp;má k&nbsp;němu přístup vlastník aplikace?</li>
							<li className="php-focus__item">Nemá ke kódu nebo serveru přístup někdo ze&nbsp;starých zaměstnanců nebo vývojářů?</li>
							<li className="php-focus__item">Má aplikace vyřešené automatické nasazování na&nbsp;produkci?</li>
							<li className="php-focus__item">Je klientovi k&nbsp;dispozici testovací prostředí, které je&nbsp;1:1 s&nbsp;produkčním prostředím a&nbsp;jednotlivé uživatelské role zde mohou otestovat nové úpravy před vystavením na&nbsp;produkci?</li>
							<li className="php-focus__item">Je v repozitáři aplikace aktuální a&nbsp;detailní readme, které vývojářům objasní, jak vyvíjet aplikaci, k&nbsp;čemu slouží, jak&nbsp;je kód veden, jaký je branching system apod?</li>
							<li className="php-focus__item">Odpovídá kód aplikace moderní struktuře objektového programování?</li>
							<li className="php-focus__item">Je vyřešeno logování chyb, aby&nbsp;bylo možné přijít na&nbsp;příčinu chybného chování nebo&nbsp;zachytit chyby, se&nbsp;kterými se uživatelé setkávají (i když je vždy nereportují)?</li>
							<li className="php-focus__item">Má aplikace detailní dokumentaci, která novým uživatelům nebo novému vývojáři popíše, jak&nbsp;se aplikace používá a&nbsp;co přináší jednotlivým uživatelským rolím?</li>
							<li className="php-focus__item">Je přístup na&nbsp;server případně do&nbsp;aplikace dostatečně bezpečný?</li>
							<li className="php-focus__item">Je vyřešeno automatické zálohování (databáze, souborového systému) a&nbsp;je popsán způsob, jak v&nbsp;případě incidentu aplikaci obnovit?</li>
						</ul>
					</Col>

					<Col xs="12" lg="4" data-sal="fade" data-sal-duration="1000">
						<div>
							<Row>
								<Col xs="4" lg="4" xl="4" data-sal="fade" data-sal-duration="1000">
									<div className="ftrs-techstack__item">
										<img loading="lazy" className="ftrs-techstack__img" src={ImgTechStack1} alt="PHP" />
									</div>
									<div className="ftrs-techstack__header">PHP</div>
								</Col>
								<Col xs="4" lg="4" xl="4" data-sal="fade" data-sal-duration="1000">
									<div className="ftrs-techstack__item">
										<img loading="lazy" className="ftrs-techstack__img" src={ImgTechStack2} alt="Nette" />
									</div>
									<div className="ftrs-techstack__header">Nette</div>
								</Col>
								<Col xs="4" lg="4" xl="4" data-sal="fade" data-sal-duration="1000">
									<div className="ftrs-techstack__item">
										<img loading="lazy" className="ftrs-techstack__img" src={ImgTechStack3} alt="Mysql" />
									</div>
									<div className="ftrs-techstack__header">Mysql</div>
								</Col>
								<Col xs="4" lg="4" xl="4" data-sal="fade" data-sal-duration="1000">
									<div className="ftrs-techstack__item">
										<img loading="lazy" className="ftrs-techstack__img" src={ImgTechStack4} alt="Elasticsearch" />
									</div>
									<div className="ftrs-techstack__header">Elasticsearch</div>
								</Col>
								<Col xs="4" lg="4" xl="4" data-sal="fade" data-sal-duration="1000">
									<div className="ftrs-techstack__item">
										<img loading="lazy" className="ftrs-techstack__img" src={ImgTechStack7} alt="Bootstrap" />
									</div>
									<div className="ftrs-techstack__header">Bootstrap</div>
								</Col>
								<Col xs="4" lg="4" xl="4" data-sal="fade" data-sal-duration="1000">
									<div className="ftrs-techstack__item">
										<img loading="lazy" className="ftrs-techstack__img" src={ImgTechStack12} alt="Sentry" />
									</div>
									<div className="ftrs-techstack__header">Sentry</div>
								</Col>
								<Col xs="4" lg="4" xl="4" data-sal="fade" data-sal-duration="1000">
									<div className="ftrs-techstack__item">
										<img loading="lazy" className="ftrs-techstack__img" src={ImgTechStack15} alt="Aws" />
									</div>
									<div className="ftrs-techstack__header">Aws</div>
								</Col>
								<Col xs="4" lg="4" xl="4" data-sal="fade" data-sal-duration="1000">
									<div className="ftrs-techstack__item">
										<img loading="lazy" className="ftrs-techstack__img" src={ImgTechStack13} alt="Docker" />
									</div>
									<div className="ftrs-techstack__header">Docker</div>
								</Col>
							</Row>
						</div>
					</Col>

				</Row>

			</Container>
		</div>

		<Footer lang="cs"></Footer>

	</Layoutcs>

)

export default SpravaPhp